import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { QuoteContext } from "../../../Context/QuoteContext"
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo"
import styled from "@emotion/styled"
import MobileNumber from "../../../components/GetQuote/Fields/MobileNumber/MobileNumber"
import {
  saveQuoteSendPin,
  updateField,
  generatePinCode,
} from "../../../Context/Actions"
import { useTheme } from "emotion-theming"
import Icon from "../../../components/Icon/Icon"
import Modal from "../../../components/GlobalAssets/Modal/modal"
import insplanet from "../../../images/insplanet-logo.jpg"
import compricer from "../../../images/compricer-logga.png"
import { apiUsers } from "../../../utils/enums"
import ButtonLoader from "../../../components/GlobalAssets/ButtonLoader"

const MobileNumberPage = ({ location }) => {
  const { state, dispatch } = useContext(QuoteContext)
  const theme = useTheme()

  useEffect(() => {
    dispatch(
      updateField({
        name: "startDate",
        value: state.fields.startDate.fieldValue,
        valid: false,
        dirty: false,
      })
    )

    dispatch(
      updateField({
        name: "telephoneNumber",
        value: state.quote.result?.telephoneNumber || "",
        valid: state.quote.result?.telephoneNumber ? true : false,
        dirty: false,
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!state.hasQuote && !state.hasLoadedQuote) {
    if (typeof window === "undefined") {
      return null
    }

    // If reloaded go back to start
    if (location.search !== "") {
      navigate(`/s?${new URLSearchParams(location.search).toString()}`)
    } else {
      navigate("/")
    }

    return null
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (state.fields.telephoneNumber.valid) {
      if (state.hasLoadedQuote) {
        dispatch(generatePinCode(state))
      } else {
        dispatch(
          saveQuoteSendPin({ state: state, nextPage: "verifiera-mobilnummer" })
        )
      }
    }
  }

  return (
    <Layout>
      <SEO title="evoli - Din hem är nu försäkrad!" noIndex={true} />
      <QuoteContainer>
        <QuoteHeroContent>
          <Icon
            name="thumbs-up"
            bgColor={theme.colors.CardContainer.iconBackground}
            iconColor={theme.colors.CardContainer.iconColor}
            iconSize="25"
          />
          <h1>Ditt hem är nu försäkrat!</h1>
          {state.hasLoadedQuote && (
            <p>
              <UpperCase>{state.quote.result.address}</UpperCase> har nu en
              giltig <LowerCase>{state.quote.result.insTypeExt}</LowerCase> från
              och med {state.quote.result.startDate}
            </p>
          )}

          {!state.hasLoadedQuote && (
            <p>
              <UpperCase>{state.quote.result.address}</UpperCase> har nu en
              giltig <LowerCase>{state.insTypeExt}</LowerCase> från och med{" "}
              {state.fields.startDate.fieldValue}
            </p>
          )}

          <h2>Betala för din försäkring</h2>

          <p style={{ color: "#727299" }}>
            Verifiera ditt mobilnummer för att betala inom 14 dagar så
            försäkringen inte blir ogiltig.
          </p>

          <ul>
            <li>
              <span>1</span>Verifiera ditt mobilnummer
            </li>
            <li>
              <span>2</span>Välj betalsätt
            </li>
          </ul>
          <FormContainer onSubmit={handleSubmit} noValidate>
            <MobileNumber />

            <Button disabled={state.isLoading}>
              {state.isLoading ? <ButtonLoader /> : "Skicka engångskod"}
            </Button>

            {state.error && <FormError>{state.error}</FormError>}

            {state.hasLoadedQuote &&
              state.loadedQuote.result.apiUser.id === apiUsers.INSPLANET.id && (
                <LogoContainer>
                  <img src={insplanet} alt="Insplanet logga" />
                </LogoContainer>
              )}
            {state.hasLoadedQuote &&
              state.loadedQuote.result.apiUser.id === apiUsers.COMPRICER.id && (
                <LogoContainer>
                  <img src={compricer} alt="Compricer logga" />
                </LogoContainer>
              )}
          </FormContainer>
        </QuoteHeroContent>
      </QuoteContainer>
    </Layout>
  )
}

export default MobileNumberPage

const UpperCase = styled.span`
  text-transform: uppercase;
`
const LowerCase = styled.span`
  text-transform: lowercase;
`

const LogoContainer = styled.div`
  max-width: 130px;
  margin-top: 10px;
`

export const QuoteContainer = styled.section`
  background: ${props => (props.bgColor ? props.bgColor : "none")};
  width: 100%;
  padding: 50px 40px 80px 40px;
  min-height: 570px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 0;
    text-align: left;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 50px 40px 80px 40px;
    margin: 0;
    text-align: left;
    flex-direction: column;
  }

  @media (max-width: 600px) and (min-width: 414px) {
    padding: 30px 20px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100vh - 46px);
  }

  @media (max-width: 375px) {
    padding: 30px 16px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    min-height: calc(100vh - 46px);
  }

  @media (max-width: 374px) {
    /* padding: 0px 15px; */
    margin: 0;
    text-align: left;
    flex-direction: column;
  }
`

const QuoteHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  h1,
  p {
    max-width: 280px;
  }

  h1 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;
    /* max-width: 240px; */

    span {
      display: block;
      color: #5558ea;
      margin-top: 10px;
    }
  }

  h2 {
    margin: 0;
    margin-top: 25px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #5558ea;
  }

  p {
    margin-top: 6px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;

    max-width: 280px;
  }

  ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    margin-top: 12px;
    margin-bottom: 10px;
    max-width: 280px;
  }

  li {
    color: #5558ea;
    font-size: 12px;
    font-weight: 500;
    display: flex;

    align-items: center;

    span {
      margin-right: 8px;
      background: ${props => (props.darkMode ? "#5558ea" : "#c4ffe1")};
      color: ${props => (props.darkMode ? "#c4ffe1" : "#5558ea")};
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
const FormContainer = styled.form`
  margin-top: 10px;
  max-width: 212px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 374px) {
    margin-top: 0px;
  }
`
const FormError = styled.div`
  color: #ff4984;
  margin: 0;
  margin-top: 5px;
  font-size: 12px;
  text-align: center;

  /* @media (max-width: 375px) {
    margin-top: -15px;
  } */
`

const Button = styled.button`
  width: 212px;
  background: linear-gradient(
    180deg,
    rgba(107, 60, 231, 1) 5%,
    rgba(87, 55, 213, 1) 100%
  );
  color: #ffffff;
  border-radius: 0.3rem;
  font-size: 16px;
  height: 48px;
  border: 0;
  margin-top: 20px;
  padding: 10px 20px;

  @media (max-width: 375px) {
    margin-top: 0;
  }

  :disabled {
    opacity: 0.3;

    :hover {
      cursor: not-allowed;
    }
  }

  :hover {
    background: linear-gradient(
      180deg,
      rgba(107, 60, 231, 0.9) 5%,
      rgba(87, 55, 213, 0.9) 100%
    );
    cursor: pointer;
  }
`
